<template>
    <v-container fluid id="users_page">

        <!--Datatable Toolbar-->
        <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
            <v-toolbar-title>{{$t('users.list')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="canCreateItem" color="primary" class="custom_button mobile_button"
                   @click="showUserDialog('create', null)"
                   :title="$t('users.create')">
                <v-icon small size="20">add</v-icon>
            </v-btn>
        </v-toolbar>

        <!--Datatable-->
        <ResponsiveDataTable :headers="headers" :items="items" :totalItems="total_items" :loading="loading"
                             :mobileBreakpoint="960" @options-changed="optionsChanged">
            <!--Status-->
            <template v-slot:status="slotProp">
                <v-switch v-if="!isSuperAdmin(slotProp.item)" v-model="slotProp.item.is_active" color="primary"
                          @click.prevent="showChangeStatusUserDialog(slotProp)">
                </v-switch>
            </template>

            <!--Buttons-->
            <template v-slot:buttons="slotProp">
                <div class="actions">
                    <v-icon v-if="canViewItem" color="primary" :title="$t('general.view')"
                            @click="showUserDialog('view', slotProp.item)">view_list
                    </v-icon>
                    <v-icon v-if="canUpdateItem" color="primary" :title="$t('general.update')"
                            @click="showUserDialog('update', slotProp.item)">edit
                    </v-icon>
                    <v-icon v-if="canDeleteItem && !isSuperAdmin(slotProp.item)" color="primary"
                            :title="$t('general.delete')" @click="showDeleteUserDialog(slotProp)">delete
                    </v-icon>
                </div>
            </template>
        </ResponsiveDataTable>

        <!--User Dialog-->
        <Dialog :show="show_item_dialog" max_width="800px" :header="item_dialog_header"
                :is_loading="item_dialog_loading" :action="item_action" @close-dialog="closeUserDialog"
                @submit-dialog="submitUserDialog">
            <template slot="content">
                <v-form autocomplete="off" class="white_bg_form">
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-text-field name="name" type="text" class="custom_field" v-model="item.name"
                                          :label="$t('general.name')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()"
                                          :error-messages="fieldErrors('name')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field name="email" type="email" class="custom_field" v-model="item.email"
                                          :label="$t('general.email')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()"
                                          :error-messages="fieldErrors('email')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="item_action === 'create'">
                            <v-text-field name="password" type="password" class="custom_field" v-model="item.password"
                                          :label="$t('general.password')" outlined clearable
                                          @input="$v.item.password.$touch()" @blur="$v.item.password.$touch()"
                                          :error-messages="fieldErrors('password')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="item_action === 'create'">
                            <v-text-field name="password_confirmation" type="password" class="custom_field"
                                          v-model="item.password_confirmation"
                                          :label="$t('general.password_confirmation')" outlined clearable
                                          @input="$v.item.password_confirmation.$touch()"
                                          @blur="$v.item.password_confirmation.$touch()"
                                          :error-messages="fieldErrors('password_confirmation')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="!isItemSuperAdmin">
                            <v-select name="medical_office_id" class="custom_field" v-model="item.medical_office_id"
                                      :items="medical_offices" :label="$t('medical_offices.single')"
                                      :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                      :disabled="isDisabled" @input="$v.item.medical_office_id.$touch()"
                                      @blur="$v.item.medical_office_id.$touch()"
                                      :error-messages="fieldErrors('medical_office_id')">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="!isItemSuperAdmin">
                            <v-select name="role" class="custom_field" v-model="item.role" :items="roles"
                                      :label="$t('general.role')" item-text="name" item-value="value" outlined clearable
                                      :disabled="isDisabled" @change="$v.item.role.$touch()"
                                      @blur="$v.item.role.$touch()" :error-messages="fieldErrors('role')">
                            </v-select>
                        </v-col>
                        <v-col cols="12" v-if="isItemDoctor">
                            <UploadFiles :postedFiles="files" accepted_files="image/png,image/jpeg" :max_files="1"
                                         ref_name="user_files" @getFiles="getFiles">
                            </UploadFiles>
                        </v-col>
                        <v-col cols="12" v-if="isItemDoctor">
                            <quill-editor ref="doctor_text" v-model="item.doctor_text"></quill-editor>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Dialog>

        <!--Change item status dialog-->
        <StatusDialog :show="show_change_status_item_dialog" :header="change_status_item_dialog_header"
                      :text="change_status_item_dialog_text" :color="change_status_item_dialog_color"
                      :is_loading="delete_item_dialog_loading" @confirm="changeUserStatus"
                      @close="closeChangeUserStatusDialog">
        </StatusDialog>

        <!--Delete item dialog-->
        <StatusDialog :show="show_delete_item_dialog" :header="$t('users.delete')"
                      :text="$t('users.delete_confirmation')" color="error" :is_loading="delete_item_dialog_loading"
                      @confirm="deleteUser" @close="closeDeleteUserDialog">
        </StatusDialog>

    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue'
    import Dialog from '../../components/Dialog.vue'
    import StatusDialog from '../../components/StatusDialog.vue'
    import UploadFiles from '../../components/UploadFiles.vue'
    import {validationMixin} from 'vuelidate'
    import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {quillEditor} from 'vue-quill-editor'

    export default {
        components: {
            ResponsiveDataTable, Dialog, StatusDialog, UploadFiles, quillEditor
        },
        mixins: [validationMixin],
        validations() {
            if (this.item_action === 'create') {
                return {
                    item: {
                        name: {
                            required
                        },
                        email: {
                            required,
                            email
                        },
                        password: {
                            required,
                            minLength: minLength(6)
                        },
                        password_confirmation: {
                            required,
                            sameAs: sameAs('password')
                        },
                        medical_office_id: {
                            required
                        },
                        role: {
                            required
                        }
                    }
                }
            } else {
                return {
                    item: {
                        name: {
                            required
                        },
                        email: {
                            required,
                            email
                        },
                        medical_office_id: {
                            required
                        },
                        role: {
                            required
                        }
                    }
                }
            }
        },
        data: self => ({
            url: 'users',

            // datatable
            headers: [],
            total_items: null,
            items: [],
            loading: false,
            options: {
                page: null,
                itemsPerPage: null,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },

            // dialog
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item: {
                name: null,
                email: null,
                password: null,
                password_confirmation: null,
                medical_office_id: null,
                role: null,
                doctor_text: null
            },
            item_id: null,
            item_action: null,
            medical_offices: [],
            initial_roles: [],
            roles: [],
            files: [],

            // change item status
            show_change_status_item_dialog: false,
            change_status_item_dialog_loading: false,
            change_status_item_dialog_header: null,
            change_status_item_dialog_text: null,
            change_status_item_dialog_color: null,
            item_status: null,

            // delete item
            show_delete_item_dialog: false,
            delete_item_dialog_loading: false,

            // actions permissions
            canViewItem: false,
            canCreateItem: false,
            canUpdateItem: false,
            canDeleteItem: false
        }),
        computed: {
            isDisabled() {
                return this.item_action === 'view';
            },
            isItemSuperAdmin() {
                return this.item.role === 'superadmin';
            },
            isItemDoctor() {
                return this.item.role === 'doctor';
            },
            locale() {
                return this.$i18n.locale;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {
            this.canCreateItem = await this.$store.dispatch('hasPermission', 'create_user');
            this.canViewItem = await this.$store.dispatch('hasPermission', 'view_user');
            this.canUpdateItem = await this.$store.dispatch('hasPermission', 'update_user');
            this.canDeleteItem = await this.$store.dispatch('hasPermission', 'delete_user');
            this.getRoles();
            this.formatRoles();

            // get medicine offices
            let response = await this.$store.dispatch('apiRequest', {method: 'get', url: 'medical-offices'});

            // get and sort order types
            this.medical_offices = this.$Helper.methods.sortArrayOfObjects({
                array: (!response.response) ? response.data : [], property: `name_${this.$i18n.locale}`
            });
        },
        watch: {
            locale(val) {
                this.formatRoles();
                this.headers = this.getHeaders();
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.name')}`, value: 'name', sortable: true},
                    {text: `${this.$t('general.email')}`, value: 'email', sortable: true},
                    {text: `${this.$t('general.role')}`, value: 'role_name', sortable: true},
                    {text: `${this.$t('general.status')}`, value: 'status', sortable: false},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ]
            },

            // Roles
            // get all roles from authenticated user permissions
            getRoles() {
                let roles_set = new Set();

                this.$store.state.permissions.forEach(obj => {

                    // find all permission's roles
                    let roles = obj.roles.filter(role => role.name !== 'superadmin');

                    // add found roles to roles array
                    roles.forEach(role_obj => roles_set.add(role_obj.name));
                });

                this.initial_roles = [...roles_set];
            },
            // format roles for select
            formatRoles() {
                this.roles = [];

                // create roles array
                this.initial_roles.forEach(item => this.roles.push({name: this.$t(`general.${item}`), value: item}));

                // sort roles
                this.roles = this.$Helper.methods.sortArrayOfObjects({array: this.roles, property: 'name'});
            },
            // check if given user is superadmin
            isSuperAdmin(user) {
                return user.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            },

            // datatable
            // apply changed options on datatable
            async optionsChanged(options) {
                let options_changed = false;
                this.loading = true;

                // check if options changed
                for (let prop in this.options) {
                    if (this.options[prop] !== options[prop]) {
                        options_changed = true;
                        break;
                    }
                }

                if (options_changed) {
                    this.options = Object.assign({}, options);
                    await this.getData();
                }
            },
            // get data from API
            async getData() {
                this.loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get', url: this.url, params: {options: this.options}
                });

                this.loading = false;

                // successful response
                if (!response.response) {
                    this.total_items = response.data.total_items;
                    this.items = response.data.items;
                }
            },

            // dialog
            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;

                // add email rules
                if (prop === 'email') {
                    !this.$v.item[prop].email && errors.push(this.$t('validation.email'));
                }

                // add password rules
                if (prop === 'password') {
                    !this.$v.item[prop].minLength && errors.push(this.$tc('validation.min_length', 1, {min: this.$v.item[prop].$params.minLength.min}));
                }

                // add password confirmation rules
                if (prop === 'password_confirmation') {
                    !this.$v.item[prop].sameAs && errors.push(this.$tc('validation.same_as', 1, {field: 'password'}));
                }

                !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // show item dialog
            showUserDialog(status, item) {
                this.show_item_dialog = true;
                this.item_dialog_header = (status === 'create') ? this.$t('users.create') : (status === 'update')
                    ? this.$t('users.update') : this.$t('users.view');
                this.item_action = status;

                if (item) {
                    this.$nextTick().then(async () => {
                        this.item_id = item.id;

                        // set user data
                        for (let prop in this.item) {
                            this.item[prop] = (prop === 'role') ? item['roles'][0].name : item[prop];
                        }

                        // if user has files
                        if (item.files.length > 0) {
                            item.files.forEach(async file => {

                                // get file from API
                                let new_file = await this.$store.dispatch('getAPIFile', {
                                    url: `${this.url}/file/${file.id}`, file: file
                                });

                                // add it to files array
                                if (new_file) {
                                    this.files.push(new_file);
                                }
                            });
                        }
                    });
                }
            },
            // close item dialog
            closeUserDialog() {
                this.$v.item.$reset();
                this.show_item_dialog = false;
                this.item_id = null;
                this.item_action = null;
                this.files = [];

                for (let prop in this.item) {
                    this.item[prop] = (prop === 'roles') ? [] : (prop === 'discount') ? 0 : null;
                }
            },
            // get all files from upload component
            getFiles(files) {
                this.files = files;
            },
            // submit item dialog
            async submitUserDialog() {
                this.$v.item.$touch();

                // login data are valid
                if (!this.$v.item.$invalid) {
                    this.item_dialog_loading = true;

                    let form_data = new FormData();

                    for (let i = 0; i < this.files.length; i++) {
                        form_data.append(`files[${i}]`, this.files[i]);
                    }

                    for (let prop in  this.item) {
                        form_data.append(prop, this.item[prop]);
                    }

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post',
                        url: this.item_id ? `${this.url}/${this.item_id}` : this.url, data: form_data,
                        upload_files: this.isItemDoctor
                    });

                    // stop dialog submit button loading
                    this.item_dialog_loading = false;

                    // successful response
                    if (!response.response) {

                        // close item dialog
                        this.closeUserDialog();

                        // update the authenticated user
                        if (response.is_authenticated) {
                            await this.$store.dispatch('getAPIUserDetails');
                        }

                        // get users
                        await this.getData();

                        // get permissions
                        await this.$store.dispatch('getAPIPermissions');
                    }
                }
            },

            // Change status
            // show change item status dialog
            showChangeStatusUserDialog(prop) {
                this.show_change_status_item_dialog = true;
                this.change_status_item_dialog_header = prop.item.is_active ? this.$t('users.deactivate') : this.$t('users.activate');
                this.change_status_item_dialog_text = prop.item.is_active ? this.$t('users.deactivate_confirmation') : this.$t('users.activate_confirmation');
                this.change_status_item_dialog_color = prop.item.is_active ? 'error' : 'success';
                this.item_id = prop.item.id;
                this.item_status = prop.item.is_active;
            },
            // close change item status dialog
            closeChangeUserStatusDialog() {
                this.show_change_status_item_dialog = false;
                this.change_status_item_dialog_loading = false;
                this.change_status_item_dialog_header = null;
                this.change_status_item_dialog_text = null;
                this.change_status_item_dialog_color = null;
                this.item_id = null;
                this.item_status = null;
            },
            // change item status
            async changeUserStatus() {
                this.change_status_item_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'patch', url: `${this.url}/status/${this.item_id}`, data: {
                        is_active: this.item_status === 1 ? 0 : 1
                    }
                });

                // successful response
                if (!response.response) {

                    // close item dialog
                    this.closeChangeUserStatusDialog();

                    // get users
                    await this.getData();
                }
            },

            // Delete item
            // show delete item dialog
            showDeleteUserDialog(prop) {
                this.show_delete_item_dialog = true;
                this.item_id = prop.item.id;
            },
            // close delete item dialog
            closeDeleteUserDialog() {
                this.show_delete_item_dialog = false;
                this.item_id = null;
            },
            // delete dialog
            async deleteUser() {
                this.delete_item_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'delete', url: `${this.url}/${this.item_id}`
                });

                this.delete_item_dialog_loading = false;

                // successful response
                if (!response.response) {

                    // close dialog
                    this.closeDeleteUserDialog();

                    // get users
                    await this.getData();

                    // get permissions
                    await this.$store.dispatch('getAPIPermissions');
                }
            }
        }
    }
</script>